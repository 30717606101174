.lp_main,
.lp_main * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.lp_main {
    background-color: #fff;
    /* width: 100%; */
    border: 1px solid #e0e3ef;
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* border-radius: 16px; */
    /* margin: 14px;
    height: 96.7%;
    margin-right: -1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; */
    /* position: relative; */

}

#dark .lp_main {
    /* background: #222c3c; */
    background: rgb(34, 44, 60);
    mix-blend-mode: normal;
    border: 1px solid #2d3543;
}

.lp_main .lpm_header {
    /* background-color: gold; */
    display: flex;
    align-items: center;
    padding: 10px 24px;
    justify-content: space-between;
    position: relative;
    z-index: 200;
}

.lp_main .lpm_searchBox {
    height: 34px;
    padding: 0 20px;
    margin-bottom: 10px;
}

.lp_main .lpm_header .lpmh_msg_text {
font-family: "Segoe UI", "Helvetica Neue", Helvetica, "Lucida Grande", Arial, Ubuntu, Cantarell, "Fira Sans", sans-serif;    /* font-weight: 500; */
    font-size: larger;
    color: #202020;
    font-weight: 700;
}

#dark .lp_main .lpm_header .lpmh_msg_text {
    color: #fff;
}

.lp_main .lpm_header .lpmh_btn_container {
    /* background: #4b40d7; */
    opacity: 0.9;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.lp_main .lpm_header .lpmh_btn_container .lpmh_btn_text {
font-family: "Segoe UI", "Helvetica Neue", Helvetica, "Lucida Grande", Arial, Ubuntu, Cantarell, "Fira Sans", sans-serif;    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
}
.lp_main .lpm_header .lpmh_btn_container .lmph_btn_icon_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    border-radius: 4px;
    height: 16px;
    width: 16px;
    border: 1px solid #ffffff;
}
.lp_main
    .lpm_header
    .lpmh_btn_container
    .lmph_btn_icon_container
    .lpmh_btn_icon {
    font-size: 11px;
    color: white;
}
.lp_main .members_section {
    flex-grow: 1;
    overflow-y: auto;
    overflow: scroll;
}
.lp_main .members_section.chat_members ul {
    width: 100%;
    padding: 0;
    margin: 0;
}
.lp_main .members_section.chat_members ul li {
    padding: .625rem;
        color: #202020;
    background-color: #fff;
    cursor: pointer;
    border-bottom: 1px solid hsl(0deg 0% 86.67% / 34%);
margin: 0 .5625rem;
border-radius: 0.75rem;
}

#dark .lp_main .members_section.chat_members ul li {
    color: #fff;
    background-color: #222c3c;
}

.lp_main .members_section.chat_members ul li label {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
        line-height: 21px;}

.lp_main .members_section.chat_members ul li:hover {
    background: #f0f2f5;
    color: #202020;
      /* background-color: #7500db; */
}

#dark .lp_main .members_section.chat_members ul li:hover {
    background-color: #3390ec;
    overflow: hidden;
    white-space: nowrap;
    color: #ffffff;
    border-radius: 0.75rem;
    --ripple-color: rgba(0, 0, 0, 0.08);
    text-decoration: none;
}

.lp_main .members_section.chat_members ul li.active {
    background-color: #2abfd4;
    overflow: hidden;
    white-space: nowrap;
    color: #ffffff;
    border-radius: 0.75rem;
    --ripple-color: rgba(0, 0, 0, 0.08);
    text-decoration: none;
}

#dark .lp_main .members_section.chat_members ul li.active {
    background: #19C39F    ;
    color: #fff;
    /* opacity: 0.9; */
}

.lp_main .members_section.chat_members ul li.unread_msgs_contact  {
    background: #fff;
    border-radius: 16px;
}
.lp_main .members_section.chat_members ul li.active.unread_msgs_contact .last_msg_text{
    color: #202020 !important;
}
.lp_main .members_section.chat_members ul li.null.unread_msgs_contact:hover, 
.lp_main .members_section.chat_members ul li.null.unread_msgs_contact:hover .last_msg_text{
    color: #202020 !important;

}
.lp_main .members_section.chat_members ul li.active.unread_msgs_contact:hover {
    color: #202020;
}.lp_main .members_section.chat_members ul li.active.unread_msgs_contact{
    color: #202020;
}
#dark .lp_main .members_section.chat_members ul li.unread_msgs_contact {
    /* background: #F1623F; */
    background: rgba(241, 98, 63, 0.21);
    border-radius: 5px;
}

.lp_main .members_section.chat_members ul li .last_msg_text{
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;

color: #707579;

mix-blend-mode: normal;
opacity: 1
}
.lp_main .members_section.chat_members ul li.active .last_msg_text {
    color: #fff;
    /* opacity: 0.7; */
}
.lp_main .members_section.chat_members ul li .last_msg_text,
.lp_main .members_section.chat_members ul li .msg_typing {
font-family: "Segoe UI", "Helvetica Neue", Helvetica, "Lucida Grande", Arial, Ubuntu, Cantarell, "Fira Sans", sans-serif;    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color:"#667781"
    
}
.lp_main .members_section.chat_members ul li.active:hover .last_msg_text {
    /* opacity: 0.7; */
    color: #fff;

}
.last_msg_text {
    /* width: 240px; */
    overflow: hidden;
    text-overflow: ellipsis;
  white-space: nowrap;
    color: #202020;

opacity: 0.4;
}

.group_text {
    /* width: 200px; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;   
    
}
.lp_main .members_section.chat_members ul li .msg_time {
font-family: "Segoe UI", "Helvetica Neue", Helvetica, "Lucida Grande", Arial, Ubuntu, Cantarell, "Fira Sans", sans-serif;    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #667781;
    text-align: right;
    
}
.lp_main .members_section.chat_members ul li.active .msg_time{
    color: #fff;
}
.lp_main .members_section.chat_members ul li.active label {
    font-weight: 500;


}
.lp_main .members_section.chat_members ul li .msg_count_div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    text-align: center;
    background-color: #2abfd4;
}
.names-message {
    display: flex;
    flex-direction: column;
    /* margin-top: 5px; */
}
.time.ms-auto.small {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.lp_main .members_section.chat_members ul li .msg_count {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
    text-align: center;
}

.lp_main .members_section::-webkit-scrollbar {
    display: none;
}

.lp_main .lpm_header .lpmh_btn_container .dropDown_main {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: absolute;
    width: 120px;
    right: 0;
    font-size: .875rem;
    margin: .125rem .25rem;
    padding: .25rem;
    font-weight: 500;
    background-color: #ffffffbb;
    box-shadow: 0 .25rem .5rem .125rem #72727240;
    transition: opacity 150ms cubic-bezier(0.2, 0, 0.2, 1), transform 150ms cubic-bezier(0.2, 0, 0.2, 1) !important;
    top: 45px;
    border-radius: 0.75rem;
    /* background-color: #e0e3ef; */
}

.lp_main .lpm_header .lpmh_btn_container .dropDown_main .dropDown_options {
    /* padding-left: 10px; */
    padding: 10px !important;
}

.lp_main
    .lpm_header
    .lpmh_btn_container
    .dropDown_main
    .dropDown_options:hover {
    /* background-color: rgba(75, 64, 215, 0.9); */
    background-color: #2abfd4;
    color: white;
    border-radius: 0.5rem;
}
.custom_avator{
    position: relative;
    object-fit: cover;
    border-radius: 50%;
    width: 49px;
    vertical-align: top;
    height: 49px;

}
.custom_avator_name{
    position: absolute;
    color: rgb(255, 255, 255);
    top: 50%;
    font-weight: 400;
    font-size: 22px;
    left: 50%;
    font-weight: 700;
    transform: translate(-50%, -50%);
}