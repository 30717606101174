@font-face {
    font-family: Manrope-Regular;
    src: url("../../assets/fonts/Manrope-Regular.ttf");
}
@font-face {
    font-family: Manrope-Medium;
    src: url("../../assets/fonts/Manrope-Medium.ttf");
}
@font-face {
    font-family: Manrope-SemiBold;
    src: url("../../assets/fonts/Manrope-SemiBold.ttf");
}
@font-face {
    font-family: Manrope-Bold;
    src: url("../../assets/fonts/Manrope-Bold.ttf");
}

/* .heading1 {
    font-family: Manrope-Regular;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
}
.heading2 {
    font-family: Manrope-Regular;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
}
.heading3 {
    font-family: Manrope-Regular;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
}
.heading4 {
    font-family: Manrope-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}
.heading5 {
    font-family: Manrope-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 22px;
} */
