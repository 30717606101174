p {
    padding: 0 !important;
    margin: 0 !important;
}
.sidebar_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
}

#dark .sidebar_wrapper {
    background-color: #222c3c;
}
.sidebar_profile_row {
    display: flex;
}
.sidebar_profile_circle {
    padding: 24px 10px 24px 15px;
}
.profile_circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    object-fit: fill;
    overflow: hidden;
    cursor: pointer;
}
.sidebar_profile_title {
    width: 100%;
    padding: 27px 0px 21px 4px;
    display: flex;
    flex-direction: column;
    color: #202020;
}

#dark .sidebar_profile_title {
    color: #ffffff;
}

.sidebar_links_row {
    background-color: #ffffff;
    flex: 5;
}

#dark .sidebar_links_row {
    background-color: rgba(34, 44, 60);
}

.navlink_ {
    text-decoration: none;
    color: rgb(119, 112, 112);
}
#dark .navlink_ {
    color: #ffffff;
}
.nav_color {
    color: blue;
    text-decoration: none;
}
.navlink_:hover {
    text-decoration: none;
}
.navlink_wrapper {
    width: 100%;
    padding: 16px 14px 16px 18px;
    display: flex;
}
.navlink_wrapper:hover {
    background: rgba(75, 64, 215, 0.08);
}

#dark .navlink_wrapper:hover {
    background: rgba(75, 64, 215, 0.04);
    color: blue;
}

.navlink_icon {
    margin: 0px 10px 0px 0px;
}
.navlink_text {
    flex: 2;
    display: flex;
    align-items: center;
}
.navlink_message {
    display: flex;
}
.message_circle_ {
    background-color: #19C39F




    ;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f1f1f1;
}
.icon_ {
    width: 25px;
    height: 25px;
}
.button_wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0px 16px 0px 16px;
}
.sidebar_fotter_row {
    flex: 1;
    width: 100%;
    color: #202020;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */
    /* position: fixed;
    bottom: 0;
    left: 0; */
}

#dark .sidebar_fotter_row {
    color: #ffffff;
}

@media screen and (max-width: 600px) {
    .sidebar_fotter_row {
        display: none;
    }
    .button_wrapper {
        display: none;
    }
}
