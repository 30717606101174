.search_container {
    width: 100%;
    height: 100%;
    display: flex;
    height: 40px;
    flex-direction: row;
    border: 1px solid #e0e3ef;
    border-radius: 0.5rem;
    justify-content: space-between;
}

.search_container.noBorder {
    border: none;
}

#dark .search_container {
    border: transparent;
    /* border-radius: 6px; */
}
.search_input {
    width: 80%;
    height: 100%;
}

.Search_ {
    background-color: #fafafa;
    border: none;
    border-radius: 6px 0 0 6px;
    color: #5f5959;
    height: 100%;
    outline: none;
    padding-left: 8px!important;
    width: 100%;
    font-size: 14px;
    font-family: 'Manrope';
}
::placeholder {
    /* color: #ffffff; */
    color: #667781;
    /* color: #efefef; */
}

#dark .Search_ {
    color: #ffffff;
}

#dark .Search_ {
    background-color: #303e51;
}

#dark .Search_::placeholder {
    color: #ffffff;
}
.search_logo {
    width: 12%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 6px 6px 0px;
    /* background-color: #303e51; */
    background-color: #fafafa;
    padding-left: 20px;
}

#dark .search_logo {
    background-color: #303e51;
    color: #ffff;
}

.logo_ {
    height: 15px;
    width: 15px;
}
