.groupInfo_main {
    background-color: #f5f6fd;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* gap: 7px; */
    overflow-y: scroll;
    position: relative;
}
span.ht_title {
    font-weight: 700;
}
.gim_img_cont {
    /* background-color: #eee; */
    width: 100%;
    /* min-height: 35%; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 28px 30px 19px;
    margin-top: 59px;
}
.gim_ic_imgDiv {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
}

.grp_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.change_img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0%;
    color: #ffffff;
    font-size: 25px;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    /* background-color: aqua; */
    opacity: 0;
}

.ci_text {
    font-size: 11px;
    text-transform: uppercase;
    padding: 0 10px;
    font-weight: 600;
}
.grp_details div {
    font-size: 14px !important;
    border-bottom: 2px solid #2abfd4 !important;
}
.grp_details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.grp_name {
    font-size: 20px;
    color: #000000;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    display: flex;
    gap: 10px;
    margin: 5px 0 0;
}
span.grp_name i.fa.fa-pencil {
    background: #ffffff;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #2f3f7d;
}
.grp_members_count {
    font-size: 16px;
    color: #000000af;
}

.change_img:hover {
    opacity: 0.7 !important;
}

.gim_header_top {
    min-height: 59px;
    /* background-color: #f15c6d; */
    background-color: #fff;
    padding-left: 25px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 2;
    gap: 20px;
}
.inner_box_crop_image{
    background-color: #2abfd4;
    z-index: 9999999;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
}

.close_icon {
    font-size: 20px;
    cursor: pointer;
}

.ht_title {
    margin-top: 2px;
}

.other_func_container {
    /* background-color: #eee; */
}

.enc_div {
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: center;
    padding: 19px 0;
    margin: 0 30px;
    max-height: 102px;
}

.enc_icon_div {
    width: 20%;
    text-align: center;
    font-size: 20px;
}

.enc_desc {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.enc_d_title {
    font-size: 17px;
    color: #000000bf;
}

.enc_d_detail {
    font-size: 14px;
    color: #000000af;
}

.gim_membersList_cont {
    background-color: #fff;
    padding: 17px 30px 0;
}

.header_div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        margin-bottom: 8px;
    }

    .mc_member_div {
        /* height: 68px; */
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 13px;
        border-bottom: 1px dotted #ddd;
        padding: 5px 0;
        justify-content: space-between;
    }
    .member_name {
        font-weight: 600;
    }
    span.member_count {
        font-size: 12px;
        font-weight: 500;
    }
.btn_remove{
    background-color: transparent;
    border: 1px solid #F44336;
    color: #F44336;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 9px;
    height: 32px;
}
.btn_remove:hover {
    background-color: #2f3f7d;
    border: 1px solid #2f3f7d;
    color: #fff;
}
    .member_img_cont {
            /* width: 60px; */
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
    }

    .m_img {
                width: 40px;
                height: 40px;
                border-radius: 20px;
                object-fit: contain;
            }


.gim_footer_cont {
    /* background-color: #eee; */
    padding: 0 30px;
    color: #f15c6d;
}

.exit_div {
        display: flex;
        align-items: center;       
        gap: 10px;
        cursor: pointer;
        border-bottom: 1px solid #ddd;
        padding: 10px 0;
        color: #a80000;
    opacity: 0.7;
}
.exit_div:hover {
opacity: 1;
}
.exit_icon {
            /* width: 24px; */
            font-size: 18px;
            
        }

        .text {
            font-size: 17px;
        }

        .report_div {
        /* height: 54px; */
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        padding: 10px 0;
        color: #a80000;
    opacity: 0.7;
        }
        .report_div:hover {
            opacity: 1;
        }
        .report_icon {
            /* width: 24px; */
            font-size: 18px;
            transform: scaleX(-1);
            margin-left: -1px;
        }

        .text {
            font-size: 17px;
        }
