.desktop_container {
    /* background-color: #222c3c; */
    width: 100%;
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
}
.desktop_topbar {
    /* height: 7vh; */
    width: 100%;
    display: none;
    /* background-color: gold; */
}
.desktop_other_component {
    /* background-color: green; */
    /* height: calc(100vh -7vh); */
    /* height: 93vh !important; */
height: 100%;
    /* width: 100vw; */
    display: flex;
    flex-direction: row;
}
.desktop_left_wrapper {
    display: flex;
    background-color: #ffffff;
    width: 18%;
    /* width: 240px; */
    height: 100%;
}

#dark .desktop_left_wrapper {
    /* background-color: #1b2430; */
    /* background-color: #1e2633; */
}
@media screen and (max-width: 900px) {
    /* .desktop_middle_wrapper {
     position: absolute;
     z-index: 1;
     
    } */
    .desktop_other_component {
/* width: 900px;
overflow-x: auto; */
    }
  
    
  }
  


  @media screen and (max-width: 700px) {
    .buttonShow {
     display: block !important;
    }
  }
  .buttonShow {
    display: none;
   }
.desktop_middle_wrapper {
    /* display: flex;
    background-color: #f2f2f2; */
    /* background-color: blue; */
    /* width: 31%;
    max-width: 390px; */
    /* width: 410px; */
    height: 100%;
    flex: 1;
    overflow: hidden;
}

#dark .desktop_middle_wrapper {
    /* background-color: #1b2430; */
    /* background: radial-gradient(
        ellipse at 48% 0%,
        rgba(241, 98, 63, 0.3) 40%,
        rgba(27, 36, 48, 1) 75%
    ); */
    background-image: url("../../assets/images/Oval.png");
    background-color: #1b2430;
    background-repeat: no-repeat;
    background-position: top;
}

.desktop_right_wrapper {
    display: flex;
    /* flex: 1; */
    /* background-color: brown; */
    background-color: #f2f2f2;
    position: relative;
    /* width: 100%; */
    /* margin: 16px 8px 16px 8px; */
    height: 100%;
    flex: 3;
    /* padding: 14px 12px 15px 0px; */
    overflow: hidden;
}

#dark .desktop_right_wrapper {
    background-color: #1b2430;
    background-image: url("../../assets/images/Oval2.png");
    background-repeat: no-repeat;
    background-position: right bottom;
}

@media screen and (max-width: 1024px) {
    /* .desktop_left_wrapper {
        display: none;
    }
    .desktop_middle_wrapper {
        width: 35%;
    }
    .desktop_right_wrapper {
        width: 65%;
    } */
}
@media screen and (max-width: 1050px) {
    .desktop_middle_wrapper {
       flex:2
    }
    .desktop_right_wrapper{
        flex:4
    }
    /* .desktop_other_component {
        height: auto;
        position: relative;

        flex-direction: column;
    }
    .desktop_right_wrapper {
        width: 100%;
    } */
}
.input-container{
    display: flex;
    padding: 10px;
    background-color: #f0f2f5;
    border-top: 1px solid #ddd;

}

.chat-textarea {
    flex: 1;
    padding: 8px;
    border: 1px solid #f0f2f5;
    border-radius: 0.5rem;
    resize: none; /* Disable manual resizing */
    overflow-y: hidden; /* By default, hidden until height exceeds max */
    min-height: 37px;   /* Default height */
    /* max-height: 150px; */
    line-height: 1.5;
    height: 37px;
    font-size: 14px;
  }

  .call-on-big{
    width: 100%;
    z-index: 210;
    position: relative;
    height: 100vh;
    transition: all 0.3s ease-in-out; /* Add this line */

  }
  @media screen and (max-width: 1000px) {

  .call-on-big{
    position: absolute;
   
    height: 300px;
    top: 50%;
    left: 50%;
        padding: 10px;
        transition: all 0.3s ease-in-out; /* Ensure transition is applied here too */

    top: 50%;
    /* width: 300px; */
    left: 50%;
    transform: translate(-50%, -50%);
  }
}