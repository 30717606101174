.topbar {
    width: 100%;
    height: 100%;
    position: sticky;
    top: 0;
    z-index: 999;
    display: flex;
    flex-direction: row;
    /* background-color: #1e2633; */
    background-color: #ffffff;
}

#dark .topbar {
    background-color: #1e2633;
}
.topbar_left {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.topbar_logo_ {
    height: 40px;
    width: 40px;
    margin-left: 14px;
    cursor: pointer;
    animation: line-spinning-forword 2s;
}
.topbar_line_ {
    height: 35px;
    width: 30px;
    margin: 0px 110px 0px 0px;
    cursor: pointer;
    color: #222c3c;
    display: flex;
}

#dark .topbar_line_ {
    color: #ffffff;
}

.line_toggle_toword {
    animation: line-spinning-forword 1s;
}
.line_toggle_forword {
    animation: line-spinning-backword 1s;
}
.topbar_Middle {
    flex: 2;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.topbar_search_box {
    width: 280px;
    height: 34px;
}
.topbar_right {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    position: relative;
}


.dark_light_toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    margin-right: 10px;
    cursor: pointer;
}

#dark .dark_light_toggle {
    color: #ffffff;
}
.topbar_title {
    margin-right: 10px;
    color: #202020;
}

#dark .topbar_title {
    color: #ffffff;
}
.topbar_arrow {
    margin-right: 23px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.arrow_ {
    height: 100%;
    width: 100%;
    cursor: pointer;
}
.sideBar_area {
    background-color: #222c3c;
    width: 240px;
    /* height: calc(100vh - 60px); */
    height: 100%;
    /* z-index: 500; */
}
.toggle_class_right {
    animation: sidebar-move-right 1s;
}
.toggle_class_left {
    animation: sidebar-move-left 1s;
}
.logout_box {
    position: absolute;
    top: 70px;
    right: 20px;
    z-index: 99;
}

/*-------------------------- Media query start -------------------------- */
@media screen and (max-width: 1442px) {
    .topbar_line_ {
        margin: 0px 100px 0px 100px;
    }
}
@media screen and (max-width: 1024px) {
    .topbar_left {
        justify-content: space-between;
    }
    .topbar_line_ {
        display: none;
    }
    .sideBar_area {
        background-color: #222c3c;
        width: 100%;
        height: auto;
    }
}
@media screen and (max-width: 778px) {
    .topbar_Middle {
        display: none;
    }
}
/*-------------------------- Media query start ---------------------------*/

/* ------------------------- Animateions start -------------------------- */
@keyframes line-spinning-forword {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes line-spinning-backword {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}

@keyframes logout-forword {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
}

@keyframes logout-backword {
    from {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(0deg);
    }
}

@keyframes sidebar-move-right {
    from {
        width: 0px;
    }
    to {
        width: 240px;
    }
}

@keyframes sidebar-move-left {
    from {
        width: 240px;
    }
    to {
        width: 0px;
    }
}

/*---------------------------Animations end----------------------------*/
