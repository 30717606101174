.button{
    background-color: #19C39F




    ;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    padding: 10px;
    cursor: pointer;
    color: #ffffff;
    outline: none;
    border: none;
}
.button:hover{
    opacity: 0.8;
}