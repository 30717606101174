.popup_container{
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    /* background-color: #808080; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup_vedio{
    box-sizing: border-box;
    width: 50%;
    height: 50%;
    background-color: green;
    border-radius: 8px;
}