.search_msgs_main {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: calc(100vh - 100px);
    opacity: 0;
    padding: 20px 10px;
    position: absolute;
    top: -10px;
    -webkit-transform: translateX(105%);
    transform: translateX(105%);
    transition: all .25s linear;
    width: 350px;
    z-index: 200;
}

#dark .search_msgs_main {
    /* background-color: rgb(34, 44, 60); */
    background-color: #1b2430;
    /* border: 1px solid gray; */
}

.search_msgs_main.smm_open {
    /* display: block; */
    opacity: 1;
    transform: translateX(-56%);
}

.search_msgs_main .smm_searchBar {
    height: 40px;
}

.search_msgs_main .smm_closeWindow {
    display: flex;
    font-size: 24px;
    color: black;
    cursor: pointer;
}

#dark .search_msgs_main .smm_closeWindow {
    color: #ffffff;
}

.search_msgs_main .smm_msgs_container {
    /* background-color: blue; */
    height: 90%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    padding: 15px 0;
}
.search_msgs_main .smm_msgs_container::-webkit-scrollbar {
    display: none;
}

.search_msgs_main .smm_msgs_container .smm_msg_main {
    background-color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 12px;
}
#dark .search_msgs_main .smm_msgs_container .smm_msg_main {
    background-color: #f2f2f2;
}

.search_msgs_main .smm_msgs_container .smm_msg_main .smm_mm_row1 {
    align-items: center;
    color: #202020;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    gap: 10px;
    justify-content: space-between;
}

.search_msgs_main .smm_msgs_container .smm_msg_main .smm_mm_row1 .mm_r1_time {
    font-size: 12px;
}

.search_msgs_main .smm_msgs_container .smm_msg_main .smm_mm_row2 {
    color: #202020;
    display: flex;
    font-size: 12px;
    gap: 10px;
    opacity: 0.4;
    font-weight: 500;
}

.search_msgs_main .smm_msgs_container .smm_msg_main .smm_mm_row2 .mm_r2_msg {
    overflow: hidden;
    /* word-break: break-all; */
    word-break: break-all;
    word-wrap: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
